// eslint-disable react/jsx-one-expression-per-line
import React, { useState } from "react";
import { Button } from "../../button/button";
import { ZInput } from "../../input/ZInput";
import { Modal } from "../../modal/Modal.component";
import { ZSelect } from "../../select/ZSelect.component";
import { OrderStatus } from "../OrderItem.types";
import { ShippingDetailModalProps } from "./ShippingDetailsModal.types";
import { Country } from "../../../admin/countries/Countries.types";
import { City } from "../../../admin/cities/Cities.types";
import { Town } from "../../../admin/towns/Towns.types";

export const ShippingDetailsModal = ({
  isOpen,
  onToggle,
  onUpdateOrder,
  productOrder,
}: ShippingDetailModalProps): JSX.Element => {
  const [companyPerson, setCompanyPerson] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [trackingNumber, setTrackingNumber] = useState<string>("");

  return (
    <Modal isOpen={isOpen} onToggle={onToggle} title="Shipping Details">
      <div className="flex flex-col text-sm">
        <text>
          {`${productOrder.deliveryAddress.firstName} ${productOrder.deliveryAddress.lastName}`}
        </text>
        <div className="mt-4" />
        <text>
          {`${productOrder.deliveryAddress.streetAddress}, 
          ${(productOrder.deliveryAddress.town as Town).name}`}
        </text>
        <text>
          {`${(productOrder.deliveryAddress.city as City).name}, ${
            (productOrder.deliveryAddress.country as Country).name
          }`}
        </text>
        <text>
          {`+${(productOrder.deliveryAddress.country as Country).dialCode}${
            productOrder.deliveryAddress.phoneNumber
          }`}
        </text>
        <div className="mt-4" />
      </div>
      <ZInput
        containerClassName="mb-4"
        onChange={(e) => setCompanyPerson(e.target.value)}
        placeholder="Delivery Company/Person"
      />
      <ZInput
        containerClassName="mb-4"
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Phone Number"
      />
      <ZInput
        containerClassName="mb-4"
        onChange={(e) => setTrackingNumber(e.target.value)}
        placeholder="Tracking Number"
      />
      <ZSelect
        containerClassName="mb-4"
        options={[{ label: "Shipped", value: OrderStatus.SHIPPED }]}
        onSelect={() => null}
        placeholder="Order Status"
      />
      <Button
        title="Save"
        onClick={
          () =>
            onUpdateOrder({
              ...productOrder,
              orderStatus: OrderStatus.SHIPPED,
              deliveryDetails: {
                deliveryCompany: companyPerson,
                phoneNumber,
                trackingNumber,
              },
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
      />
    </Modal>
  );
};
