// eslint-disable react/jsx-one-expression-per-line
import React, { useState } from "react";
import { Button } from "../../button/button";
import { Modal } from "../../modal/Modal.component";
import { ZSelect } from "../../select/ZSelect.component";
import { OrderStatus } from "../OrderItem.types";
import { DeliveredModalProps } from "./DeliveredModal.types";
import { Town } from "../../../admin/towns/Towns.types";
import { City } from "../../../admin/cities/Cities.types";
import { Country } from "../../../admin/countries/Countries.types";

export const DeliveredModal = ({
  isOpen,
  onToggle,
  onUpdateOrder,
  order,
}: DeliveredModalProps): JSX.Element => {
  const [status, setStatus] = useState<OrderStatus>(order.orderStatus);
  return (
    <Modal isOpen={isOpen} onToggle={onToggle} title="Shipping Details">
      <div className="flex flex-col text-sm">
        <text>
          {`${order.deliveryAddress.firstName} ${order.deliveryAddress.lastName}`}
        </text>
        <div className="mt-4" />
        <text>
          {`${order.deliveryAddress.streetAddress}, 
          ${(order.deliveryAddress.town as Town).name}`}
        </text>
        <text>
          {`${(order.deliveryAddress.city as City).name}, ${
            (order.deliveryAddress.country as Country).name
          }`}
        </text>
        <text>
          {`+${(order.deliveryAddress.country as Country).dialCode}${
            order.deliveryAddress.phoneNumber
          }`}
        </text>
        <div className="mt-4" />
      </div>

      <ZSelect
        containerClassName="mb-4"
        options={[
          { label: "Delivered", value: OrderStatus.DELIVERED },
          { label: "Created", value: OrderStatus.CREATED },
        ]}
        onSelect={(option) => setStatus(option?.value as OrderStatus)}
        placeholder="Order Status"
      />
      <Button
        title="Save"
        onClick={() => onUpdateOrder({ ...order, orderStatus: status })}
      />
    </Modal>
  );
};
