import React from "react";

export const OrderColumn = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}): JSX.Element => (
  <div className="bg-gray-200 p-4 flex flex-1 flex-col min-h-[80vh] rounded-md">
    <div className="text-lg font-bold">{title}</div>
    <div className="w-full border-b-[1px] mt-2 border-b-gray-300" />
    <div className="mt-4 w-full gap-4 flex flex-col">{children}</div>
  </div>
);
